<template>
  <li v-if="!$apollo.loading">
    <b-button
      v-if="$hasPrivileges(
          $privileges('CAN_PUBLISH_ITEMS'),
          isSysAdmin,
          privilegesForItem,
        ) && showPublishPageButton && !disableButtonsOnAutosave"
      @click="publishPage"
      class="mr-2 green-button-link"
    >
      <b-icon icon="cloud-upload-fill" aria-hidden="true" class="top--1 mr-1"></b-icon>
      {{ $t('buttons.publishPage') }}
    </b-button>
    <b-button
      v-if="$hasPrivileges(
          $privileges('CAN_PUBLISH_ITEMS'),
          isSysAdmin,
          privilegesForItem,
        ) && showPublishChangesButton && !disableButtonsOnAutosave"
      @click="publishPage"
      class="mr-2 green-button-link"
    >
      <b-icon icon="cloud-upload-fill" aria-hidden="true" class="top--1 mr-1"></b-icon>
      {{ $t('buttons.publishChanges') }}
    </b-button>
    <b-button
      data-page-step="5"
      @click="previewPage"
      class="mr-4 transparent-white-button"
    >
      <b-icon icon="eye" aria-hidden="true" class="top--1 mr-1"></b-icon>
      {{ $t('buttons.preview') }}
    </b-button>
    <!-- <b-button
      v-if="!isPublished"
      class="dark-grey-buttons mr-2"
    >
      <b-icon
        id="header-tooltip-eye-changed"
        icon="link"
        aria-hidden="true"
        class="top--1"
      ></b-icon>
    </b-button> -->
    <b-button
      v-if="isPublished"
      class="dark-grey-buttons mr-2"
      @click="showUrlModal"
    >
      <b-icon
        id="header-tooltip-eye"
        icon="link"
        aria-hidden="true"
        class="top--2"
      ></b-icon>
    </b-button>
    <b-tooltip
      v-if="!isPublished"
      target="header-tooltip-eye-changed"
    >
      <small>{{ $t('webmag.publishProjectChangesFirst') }}</small>
    </b-tooltip>
    <b-tooltip
      v-if="isPublished"
      target="header-tooltip-eye"
    >
      <small>{{ $t('webmag.showItemUrl') }}</small>
    </b-tooltip>
  </li>
</template>

<script>
import SetItemUrlWindowFromCache from '@/graphQlQueries/mutations/setItemUrlWindowFromCache';
import SetPageHasToBePublishedInCache from '@/graphQlQueries/mutations/setPageHasToBePublishedInCache';
import SetPreviewWindowInCache from '@/graphQlQueries/mutations/setPreviewWindowInCache';
import GetButtonsDisabledOnAutosaveFromCache from '@/graphQlQueries/queries/getButtonsDisabledOnAutosaveFromCache';
import GetPageHasToBePublishedFromCache from '@/graphQlQueries/queries/getPageHasToBePublishedFromCache';

export default {
  name: 'HeaderActionPageButtons',
  data: () => ({
    isPublished: true,
    hasChanged: false,
    hasParentProject: false,
    parentProjectIsPublished: false,
    disableButtonsOnAutosave: false,
  }),
  props: {
    isSysAdmin: {
      type: Boolean,
      required: true,
    },
    privilegesForItem: {
      type: Array,
    },
  },
  apollo: {
    disableButtonsOnAutosave: {
      query: GetButtonsDisabledOnAutosaveFromCache,
      update(data) {
        return data.buttonsDisabledOnAutosave.isButtonsDisabledOnAutosave;
      },
    },
    pageHasToBePublishedQuery: {
      query: GetPageHasToBePublishedFromCache,
      update(data) {
        this.hasParentProject = data.pageHasToBePublished.hasParentProject;
        this.parentProjectIsPublished = data.pageHasToBePublished.parentProjectIsPublished;
        this.hasChanged = data.pageHasToBePublished.hasChanged;
        this.isPublished = data.pageHasToBePublished.isPublished;
      },
    },
  },
  computed: {
    showPublishChangesButton() {
      if (
        this.hasParentProject
        && this.parentProjectIsPublished
        && this.isPublished
        && this.hasChanged
      ) {
        return true;
      }
      return !this.hasParentProject && this.isPublished && this.hasChanged;
    },
    showPublishPageButton() {
      if (this.hasParentProject && this.parentProjectIsPublished && !this.isPublished) {
        return true;
      }
      return !this.hasParentProject && !this.isPublished;
    },
  },
  methods: {
    showUrlModal() {
      this.$apollo.mutate({
        mutation: SetItemUrlWindowFromCache,
        variables: {
          openItemUrlWindow: true,
          itemPath: null,
        },
      });
    },
    publishPage() {
      this.$apollo.mutate({
        mutation: SetPageHasToBePublishedInCache,
        variables: {
          isPublished: true,
          hasChanged: false,
          publishButtonIsPressed: true,
          hasParentProject: this.hasParentProject,
          parentProjectIsPublished: this.parentProjectIsPublished,
        },
      });
    },
    previewPage() {
      document.body.className = `${document.body.className} preview-modal-open`;
      this.$apollo.mutate({
        mutation: SetPreviewWindowInCache,
        variables: {
          isPreviewWindowOpen: true,
        },
      });
    },
  },
};
</script>
